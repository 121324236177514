/**
 * Created by jahansj on 15/02/2016.
 */
define(['app', 'ePopup'], function (app, ePopup) {
  var popup = require('ePopup');

  var ourBtn = document.querySelector('.link-epopup');
  var epopupContent = document.querySelector('.basket-warning-popup');

  app.element.on('click', function () {
    new popup(epopupContent, 'myproteinNorwayCheckout', true);
  }, ourBtn, false);
});
